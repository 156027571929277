import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, Dialog } from "@material-ui/core";
import { CloseContainer, Links, MainContainer } from "./styles.popup";

import bell from "../../assets/img/bell.png";
import { IconClose } from "../../assets/icons/close-icon";

import { routes } from "../../constants.js/misc";

const NotificationPopup = ({ openNotification, onClose }) => {
  const history = useHistory();
  const goTo = () => {
    onClose();
    history.push(`${routes.ordersPage}`);
  };
  const PayPalUrl =
    "https://www.loom.com/share/8ad57f280d46492e84f61ddd5bf9aabc?sid=62d4381a-c6f0-42bd-899c-17611c9e8e34";
  const wompiUrl =
    "https://www.loom.com/share/2c12c11e852c4805b37291dd970077c8?sid=2dbed171-4eea-418b-95af-3a3ca87b8ae2";
  const goPayPal = () => window.open(PayPalUrl);
  const goWompi = () => window.open(wompiUrl);

  useEffect(() => {
    localStorage.setItem("showPopupLinks", "true");
    localStorage.removeItem("showPopupOrders");
    localStorage.removeItem("showPopupExcuse");
  }, []);
  return (
    <Dialog open={openNotification}>
      <MainContainer>
        <CloseContainer onClick={onClose}>
          <IconClose />
        </CloseContainer>
        <img src={bell} alt="notification image" />
        <h1>
          ⚠️ Atención: Evita Fraudes ⚠️
        </h1>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <p>
            Los únicos números oficiales para pagos y renovaciones son:
          </p>
          
          <p style={{ 
            marginLeft: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: '8px' 
          }}>
            <span role="img" aria-label="phone">📞</span> 
            +1 754 329 6020
          </p>
          
          <p style={{ 
            marginLeft: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: '8px' 
          }}>
            <span role="img" aria-label="phone">📞</span> 
            +57 300 315 4556
          </p>
  
          <p style={{ 
            marginTop: '8px',
            color: '#d32f2f',
            fontWeight: 'bold' 
          }}>
            Si recibes mensajes desde otros números, NO realices pagos.
          </p>
  
          <p>
            Hemos detectado intentos de fraude desde <span style={{ fontWeight: 'bold' }}>+57 313 780 9342</span>. 
            Cualquier pago a esa línea no será válido y no nos hacemos responsables por activaciones no procesadas.
          </p>
        </div>
  
        <Button 
          fullWidth 
          variant="contained" 
          color="primary" 
          onClick={onClose}
          style={{ marginTop: '24px' }}
        >
          Entendido
        </Button>
      </MainContainer>
    </Dialog>
  );
};

export default NotificationPopup;
