import axios from 'axios';
import store from '../../src/store';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_PROXY_URL
});

export const serverInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL
});

export const twilioVerifyInstance = axios.create({
    baseURL: process.env.REACT_APP_TWILIO_VERIFY_API_URL
});

// export const hubSpotInstance = axios.create({
//     baseURL: process.env.REACT_APP_HUBSPOT_API_URL
// });

axiosInstance.interceptors.request.use(config => {
    config.headers.email = store.getState().auth.userEmail || '';
    return config;
}, (error) => Promise.reject(error));

// axiosInstance.interceptors.response.use(
//     res => res,
//     err => {
//         if( err.response.data.message === 'invalid or expired jwt' || 
//             err.response.data.message === 'missing or malformed jwt'
//         ) {
//             const acept = window.confirm('Tu sesión ha caducado, inicia sesión de nuevo')
//             if (acept) {
//                 window.location.href = '/'
//             } else {
//                 window.location.href = '/'
//             }
//         }
//         return Promise.reject(err)
//     }
// )

let isRedirecting = false // Bandera para controlar la redirección

axiosInstance.interceptors.response.use(
    res => res,
    err => {
        if (!isRedirecting && (err.response.data.message === 'invalid or expired jwt' || err.response.data.message === 'missing or malformed jwt')) {
            isRedirecting = true; // Se establece la bandera de redirección

            const acept = window.confirm('Tu sesión ha caducado, inicia sesión de nuevo')
            if (acept) {
                window.location.href = '/'
            } else {
                window.location.href = '/'
            }
        }
        return Promise.reject(err)
    }
)

twilioVerifyInstance.interceptors.request.use(config => {
    config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin' : '*',
    }
    return config;
}, (error) => Promise.reject(error));

twilioVerifyInstance.interceptors.response.use(
    res => res,
    err => {
        return Promise.reject(err);
    }
);

// hubSpotInstance.interceptors.request.use(config => {
//     config.headers = {
//         'Authorization': `Bearer ${process.env.REACT_APP_HUBSPOT_API_KEY}`,
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin' : '*',
//     }
//     return config;
// }, (error) => Promise.reject(error));

// hubSpotInstance.interceptors.response.use(
//     res => res,
//     err => {
//         return Promise.reject(err);
//     }
// );