// ReactJS
import { Button, FormControl, FormHelperText, FormLabel, OutlinedInput } from "@material-ui/core";
import React, {useState} from "react";

// Icon [SVG]
import visibility from '../../../assets/icons/visibility.svg'
import visibilityOff from '../../../assets/icons/visibilityOff.svg'
import { useSnackbar } from "notistack";
import { successToast } from "../../../constants.js/misc";

const WompiPayForm = ({ errors, register, methodLabelAccountId, methodAccountId, config, methodLabelApikey, methodApikey, integrity, eventWompiKey, storeId }) => {
    const { enqueueSnackbar } = useSnackbar()
    const urlEvent = `${process.env.REACT_APP_SERVER_URL}/api/v1/notification/wompi/${storeId}`

    const [password, setPassword] = useState(false)

    const showPassword = () => {
        setPassword( prevState => !prevState )
    }

    const copyLink = () => {
        navigator.clipboard.writeText(urlEvent)
        .then(() => {
            enqueueSnackbar('URL copiada', successToast)
        })
        .catch(err => {
            console.error('Error al copiar al portapapeles:', err)
        })
    }

    return (
        <>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <FormLabel
                    variant="body1"
                    htmlFor="accountId"
                >
                    {methodLabelApikey}
                </FormLabel>
                <div style={{ display: 'flex', alignItems: 'center', margin:''}}>
                    <OutlinedInput
                        type={password ? "text" : "password"}
                        id="accountId"
                        defaultValue={methodAccountId ? config?.client_id : ''}
                        fullWidth
                        error={errors?.accountId ? true : false}
                        {...register('accountId')}
                    />
                    <img 
                        style={{ width:'25px', height: '25px', position: 'absolute', right: '15px', cursor: 'pointer'}}
                        src={password ? visibilityOff : visibility } 
                        onClick={showPassword}    
                    />
                </div>
                <FormHelperText error={errors?.accountId ? true : false}>{errors?.accountId ? ''/* t('Config.domainInput.nameError') */ : errors?.accountId?.message}</FormHelperText>
            </FormControl>

            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <FormLabel
                    variant="body1"
                    htmlFor="apiKey"
                >
                    {methodLabelAccountId}
                </FormLabel>
                <OutlinedInput
                    id="apiKey"
                    defaultValue={methodApikey ? config?.client_secret : ''}
                    fullWidth
                    error={errors?.apiKey ? true : false}
                    {...register('apiKey')}
                />
                <FormHelperText error={errors?.apiKey ? true : false}>{errors?.apiKey ? ''/* t('Config.domainInput.nameError') */ : errors?.apiKey?.message}</FormHelperText>
            </FormControl>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <FormLabel
                    variant="body1"
                    htmlFor="integrity"
                >
                    Firma de integridad
                </FormLabel>
                <OutlinedInput
                    id="integrity"
                    defaultValue={integrity ? config?.integrity : ''}
                    fullWidth
                    error={errors?.integrity ? true : false}
                    {...register('integrity')}
                />
                <FormHelperText error={errors?.integrity ? true : false}>{errors?.integrity ? '' : errors?.integrity?.message}</FormHelperText>
            </FormControl>
            <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <FormLabel
                    variant="body1"
                    htmlFor="integrity"
                >
                    Event Wompi key 
                </FormLabel>
                <OutlinedInput
                    id="integrity"
                    defaultValue={eventWompiKey ? config?.event_wompi_key : ''}
                    fullWidth
                    error={errors?.eventWompiKey ? true : false}
                    {...register('eventWompiKey')}
                />
                <FormHelperText error={errors?.eventWompiKey ? true : false}>{errors?.eventWompiKey ? '' : errors?.eventWompiKey?.message}</FormHelperText>
            </FormControl>
            <FormLabel variant="body1">URL de notificación de eventos</FormLabel>
            <h4>{urlEvent}</h4>
            <p>Copia esta URL de notificación de eventos y pégala en la sección de configuración de tu pasarela de pago. Así, recibirás actualizaciones importantes sobre tus transacciones en tiempo real.</p>
            <Button 
                onClick={copyLink}
                variant="contained"
                color="secondary"
            >
                copiar url
            </Button>
        </>
    );
}

export default WompiPayForm;