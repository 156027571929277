import { useMemo } from 'react';

export const useWelcomeMessage = () => {
    const timesWelcomePopup = 3

    const showPopup = localStorage.getItem("showWelcomePopup") === 'true';

    const setWelcomeMessage = () => {
        const showPopup = localStorage.getItem("showWelcomePopup");
        const times = localStorage.getItem("timesWelcomePopup");
        if(!showPopup && !times){
            localStorage.setItem("showWelcomePopup", true);
            localStorage.setItem("timesWelcomePopup", timesWelcomePopup);
        }
        if(Number(times) === timesWelcomePopup){
            localStorage.setItem("showWelcomePopup", false);
        }
        if(Number(times) < timesWelcomePopup){
            localStorage.setItem("timesWelcomePopup", Number(times) + 1);
            localStorage.setItem("showWelcomePopup", true);
        }
    }

    const initWelcomeMessage = useMemo(() => setWelcomeMessage, []);

    const setShowPopup = (value) => {
        localStorage.setItem("showWelcomePopup", value);
    }

    return {
        initWelcomeMessage,
        showPopup,
        setShowPopup
    };
}