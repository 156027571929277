import i18n from 'i18next';

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^\d{7,13}$/;
export const passWordRegex = /^.{6,}$/;
export const numberRegex = /^\d+$|^$/;
export const commaSeparatedEmailsRegex = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+)[\W]*$/;

export const routes = {
    addPaymentProviders: '/config/paymentProviders/add',
    bill: '/bill',
    billing: '/config/billing',
    clientPage: '/clients',
    code: '/code',
    collectionsPage: '/collections',
    config: '/config',
    createCollection: '/create-collection',
    createOrder: '/order/create',
    createProduct: '/products/create',
    createUserRole: '/user-roles/create',
    createUserStore: '/user-stores/create',
    domain: '/config/domain',
    editOrder: '/edit',
    form: '/config/form',
    general: '/config/general',
    homeUrl: '/',
    inventoryPage: '/inventory',
    inventoryImportPage: '/inventory/import',
    importProducts: '/products/import',
    legal: '/config/legal',
    loginUrl: '/',
    ManageUserRole: '/user-roles/create',
    massiveUploadTable: '/products/import-table',
    mobileManageCategories: '/manage-categories',
    ordersPage: '/orders',
    payBill: '/pay-bill',
    paymentProviders: '/config/paymentProviders',
    payments: '/payments',
    productsTable: '/products',
    qrCode: '/config/qr-code',
    recoveryPassword: '/password-recovery',
    resetPassword: '/reset',
    shipping: '/config/shipping',
    //signUpDetailsUrl: '/sign-up/details',
    signUpUrl: '/sign-up',
    signUpVerifyUrl: '/sign-up/verify',
    signUpUserInfoUrl: '/sign-up/discount',
    statsPage: '/stats',
    store: '/store',
    thankU: '/thank-you',
    updateCollection: '/edit-collection',
    updateProduct: '/products/update',
    updateUserRole: '/user-roles/update',
    updateUserStore: '/user-stores/update',
    upgradePaymentProviders: '/config/paymentProviders/update',
    uploadingProducts: '/products/uploading',
    usersRoles: '/users-roles',
    userStores: '/user-stores',
    welcomePage: '/welcome-10x',
    downloadReportPage: '/descargar-reporte-usuarios'
}

export const errorToast = {
    variant: 'error',
    autoHideDuration: 4500
}

export const successToast = {
    variant: 'success',
    autoHideDuration: 4500
}

export const infoToast = {
    variant: 'info',
    autoHideDuration: 4500
}

export function* cartesian(head, ...tail) {
    let remainder = tail.length ? cartesian(...tail) : [[]];
    for (let r of remainder) for (let h of head) yield [h, ...r];
}


const langDictionary = {
    es: 'es-CO',
    br: 'pt-BR'
}

const currencyDictionary = {
    es: 'COP',
    br: 'BRL'
}

const options = {
    dateStyle: 'long'
}

export const formatDate = value => {
    return new Intl.DateTimeFormat(langDictionary[i18n.language]).format(new Date(value));
}

export const formatDateLarge = value => {
    return new Intl.DateTimeFormat(langDictionary[i18n.language], options).format(new Date(value));
}

export const formatNumber = (value, currency = false, toParts = false) => {
    const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: currency || 'COP'
    });

    if (toParts) return formatter.formatToParts(value);
    if (!currency || currency === 'COP') {
        return formatter.format(value).replace(',00', '');
    }
    return formatter.format(value);
};

export const currencyToString = (currency) => {
    return new Intl.NumberFormat(langDictionary[i18n.language], {
        style: 'currency',
        currencyDisplay: 'name',
        currency
    }).format().replace('NaN', '').trim()
};


export const copyText = (text, messageSuccess) => {
    document.addEventListener('copy', (e) => {
        e.clipboardData.setData('text/plain', (text));
        e.preventDefault();
        document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
};