import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { CardHeader, LinearProgress, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';


import { createProductImagesService, deleteProductImagesService, deleteBulkProductImagesService } from '../../../services/products';

import { errorToast, successToast } from '../../../constants.js/misc';
import {
    CurrentImageImage,
    ImageContainer,
    DeleteMask, ImageUpload,
    ImageIconUpload, DnDWrapper,
    ResponsiveStyles
} from './ProductImages.styles';
import DeleteProductDialog from '../DeleteProductDialog/DeleteProductDialog';
import AddIconUploadImage from '../../../assets/icons/addiconimages.svg';
import UploadImage from '../../../assets/img/upload-image.svg';
import Resizer from "react-image-file-resizer";


const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });




export const CurrentImage = ({ image, alt, handleClick, isDeleting, wide = false, thumb = false }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const classes = ResponsiveStyles();

    return (

        <ImageContainer wide={wide} thumb={thumb} className={isTabletOrMobile ? classes.inBlock : ''}>
            {isDeleting && <LinearProgress style={{ zIndex: '6' }} />}
            <CurrentImageImage
                src={image}
                alt={alt}
                style={{
                    width: '150px',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '10px'
                }}
                />
            <DeleteMask className="delete-mask" wide={wide}>
                <IconButton disabled={isDeleting} onClick={handleClick} aria-label="delete" color="secondary">
                    <DeleteIcon />
                </IconButton>
            </DeleteMask>
        </ImageContainer>
    )
}

const ProductImages = ({
    productId = null,
    storeId,
    onFilesLoaded,
    onFilesError,
    productData,
    handleImageDeletion,
    files,
    setFiles,
    elevation = 2,
    processId
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    let { id } = useParams();
    const [hasImages, setHasImages] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [imageCompressionLoading, setImageCompressionLoading] = useState(false)
    const [currentId, setCurrentId] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [isMassive, setIsMassive] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const imgExist = productData?.product_images !== undefined ? productData?.product_images?.length : 0
    const maxImages = 10 - imgExist

    const classes = ResponsiveStyles();


    useEffect(() => {
        if (productId) {
            if (files.length) {
                createProductImagesService(storeId, productId, files)
                    .then(res => {
                        onFilesLoaded();
                    })
                    .catch(err => {
                        console.log(err);
                        onFilesError();
                    });
            } else {
                onFilesLoaded();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    /**
     * Deletes the currently selected image
     */
    const deleteImage = () => {
        setDeleteDialogOpen(false);
        if (currentId && id || processId) {
            const functionToCall = isMassive ? deleteBulkProductImagesService : deleteProductImagesService;
            setDeleting(true);
            functionToCall(storeId, id || processId, currentId)
                .then(() => {
                    handleImageDeletion(currentId);
                    setDeleting(false);
                    enqueueSnackbar(t('Product.deleteImageSuccess'), successToast);
                    setCurrentId(false);
                })
                .catch(() => {
                    setCurrentId(false);
                    setDeleting(false);
                    enqueueSnackbar(t('Product.deleteImageError'), errorToast)
                });
        }
    }

    const openDialogAndSetCurrentId = (image) => {
        setIsMassive(image.task_process_id ? true : false);
        setCurrentId(image.id);
        setDeleteDialogOpen(true);
    }
    // Max size in product images

    const maxUncompressedSize = 0.6 * 1000 * 1000
    const maxSize = 5 * 1000 * 1000
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/jpeg, image/png,', 
        onDrop: async (file) => {
            let fileToBeProcessed = file[0]
            if (fileToBeProcessed.size > maxSize) {
                return enqueueSnackbar(`${fileToBeProcessed.name} ${t('Product.imageTooBig')}`, errorToast)
            } else if (fileToBeProcessed.size > maxUncompressedSize) {

                try {
                    setImageCompressionLoading(true)
                    fileToBeProcessed = await resizeFile(file[0])
                    setImageCompressionLoading(false)
                    setFiles(prevFiles => [...prevFiles, fileToBeProcessed].slice(0, maxImages))
                }catch(e) {
                    setImageCompressionLoading(false)
                    return enqueueSnackbar(`${fileToBeProcessed.name} ${t('Product.compressingError')}`, errorToast)
                }
            } else {
                setFiles(prevFiles => [...prevFiles, fileToBeProcessed].slice(0, maxImages))
            }

        }
    })
    const removeImgPreview = (index) => {
        const items = files;
        items.splice(index, 1);
        setFiles([...items]);
    };
    useEffect(() => {
        if (productData.product_images && productData.product_images.length > 0) setHasImages(true);
    }, [productData])
    
    return (
        <Card elevation={elevation} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('Product.titleImages')} />
            <CardContent>
                <Grid container spacing={isTabletOrMobile ? 0 : 3} >
                    <Grid item className={isTabletOrMobile ? classes.mobile : classes.full}>
                        {isTabletOrMobile &&
                            <DnDWrapper {...getRootProps()} style={{
                                display: 'inline-block',
                                position: 'relative',
                                top: '-34px',
                                padding: 17
                            }}>
                                <input {...getInputProps()} />
                                {imageCompressionLoading ? (
                                        <CircularProgress
                                            key={Math.random()}
                                            size={30}
                                            color="secondary" 
                                        /> 
                                    ):
                                    null 
                                }
                                {isDragActive 
                                ? <ImageUpload src={UploadImage} ></ImageUpload>
                                : <>
                                    {!hasImages && productData && files?.length === 0 && productData?.product_images?.length === 0 && <ImageUpload src={UploadImage} ></ImageUpload>}
                                    {!hasImages && files?.length === 0 && !productData && <ImageUpload src={UploadImage} ></ImageUpload>}
                                    { (maxImages > 0 && maxImages - files?.length !== 0) &&
                                        <ImageIconUpload asExitsFiles={productData && productData?.product_images?.length > 0 ? true : false} src={AddIconUploadImage}></ImageIconUpload> 
                                    }
                                </>
                                }
                            </DnDWrapper>
                        }

                        {hasImages && productData.product_images.length > 0 &&
                            productData.product_images.map((image, index) =>
                                <CurrentImage
                                    isDeleting={deleting && currentId === image.id}
                                    handleClick={() => openDialogAndSetCurrentId(image)}
                                    key={index}
                                    image={process.env.REACT_APP_IMAGES_API_URL + image.picture_url}
                                    alt={productData.name} />
                            )}
                        {
                            files.map((image, index) =>{
                                return (
                                    <>
                                        <CurrentImage
                                            handleClick={() => removeImgPreview(index)}
                                            key={index}
                                            image={URL.createObjectURL(image)} />
                                    </>
                                )})
                            }
                        <>
                            {!isTabletOrMobile &&
                                <DnDWrapper {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {imageCompressionLoading ? (
                                        <CircularProgress
                                            key={Math.random()}
                                            size={30}
                                            style={{margin:'10px'}}
                                            color="secondary" 
                                        /> 
                                        ):
                                        null
                                    }
                                    {isDragActive 
                                    ? <ImageUpload src={UploadImage} ></ImageUpload>
                                    : <>
                                        {!hasImages && productData && files?.length === 0 && productData?.product_images?.length === 0 && <ImageUpload src={UploadImage} ></ImageUpload>}
                                        {!hasImages && files?.length === 0 && !productData && <ImageUpload src={UploadImage} ></ImageUpload>}
                                        { (maxImages > 0 && maxImages - files?.length !== 0) &&
                                            <ImageIconUpload asExitsFiles={productData && productData?.product_images?.length > 0 ? true : false} src={AddIconUploadImage}></ImageIconUpload> 
                                        }
                                    </>
                                    }
                                </DnDWrapper>
                            }
                        </>
                    </Grid>
                </Grid>

                {hasImages &&
                    <DeleteProductDialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        onConfirm={() => deleteImage()}
                        title={t('Product.deleteImageTitle')}
                        message={t('Product.deleteImageMessage')}
                        cancelText={t('Product.deleteImageCancel')}
                        confirmText={t('Product.deleteImageConfirm')}
                    />
                }
            </CardContent>
            <Typography
                variant='h6'
                style={{ margin: '0 0 16px 16px' }}
            >
                {t('Product.dimensions')}
            </Typography>
        </Card>
    );
};

const mapStateToProps = ({ auth, products }) => ({
    storeId: auth.storeId,
    processId: products.processId
});

export default connect(
    mapStateToProps
)(ProductImages);