// React dependencies
import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory, Route, Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

// external dependencies
import clsx from 'clsx';


// Material UI dependencies
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// SVG images
import ClientsIcon from "../../assets/icons/clients.svg"
import InventoryIcon from "../../assets/icons/inventory.svg"
import CollectionIcon from "../../assets/icons/collections.svg"
import ConfigIcon from "../../assets/icons/config.svg"
import EditIcon from "../../assets/icons/edit.svg"
import HomeIcon from "../../assets/icons/home.svg"
import logo from '../../assets/img/logo.svg'
import { BellNotification } from "../../assets/icons/notificationIcon.js"
import OrdersIcon from "../../assets/icons/orders.svg"
import OvaloIcon from "../../assets/icons/ovalo.svg"
import ProductIcon from "../../assets/icons/products.svg"
import WPIcon from "../../assets/icons/whatsapp.svg"

// Internal components
import ActionCategorieMobile from '../../pages/ActionCategorieMobile/ActionCategorieMobile'
import AddPaymentProvidersPage from '../../pages/AddPaymentProvidersPage/AddPaymentProvidersPage'
import BillDetailPage from '../../pages/BillDetailPage/BillDetailPage'
import BillingPage from '../../pages/BillingPage/BillingPage'
import BillPaymentPage from '../../pages/BillPaymentPage/BillPaymentPage'
import ClientsPage from '../../pages/ClientsPage/ClientsPage'
import Collections from '../../pages/CollectionsPage/CollectionsPage'
import ConfigPage from '../../pages/ConfigPage/ConfigPage'
import CreateOrder from '../../pages/CreateOrder/CreateOrder'
import DomainPage from '../../pages/DomainPage/DomainPage'
import FooterMenuBar from '../../components/MobileUX/FooterBar/FooterBar'
import FormPage from '../../pages/FormPage/FormPage'
import GeneralPage from '../../pages/GeneralPage/GeneralPage'
import HomePage from '../../pages/HomePage/HomePage'
import HomePageMobile from '../../pages/HomePageMobile/HomePageMobile'
import InventoryPage from '../../pages/InventoryPage/InventoryPage'
import InventoryImportPage from '../../pages/InventoryImportPage/InventoryImportPage'
import ImportProductsPage from '../../pages/ImportProductsPage/ImportProductsPage'
import LegalPage from '../../pages/LegalPage/LegalPage'
import ManageCollection from '../../components/ManageCollection/ManageCollection'
import ManageProductPage from '../../pages/ManageProductPage/ManageProductPage'
import ManageStorePage from '../../pages/ManageStorePage/ManageStorePage'
import ManageUserRole from '../../pages/ManageUserRole/ManageUserRole'
import ManageUserStorePage from '../../pages/ManageUserStorePage/ManageUserStorePage'
import MassiveUploadTable from '../../pages/MassiveUploadTable/MassiveUploadTable'
import MobileDrawer from '../../components/MobileDrawer/MobileDrawer'
import OrderInfoPage from '../../pages/OrderInfoPage/OrderInfoPage'
import OrdersTablePage from '../../pages/OrdersTablePage/OrdersTablePage'
import PaymentPage from '../../pages/PaymentPage/PaymentPage'
import PaymentProvidersPage from '../../pages/PaymentProvidersPage/PaymentProvidersPage'

import PreviewLinkStore from '../../components/PreviewLinkStore/PreviewLinkStore'
import ProductsTablePage from '../../pages/ProductsTablePage/ProductsTablePage'
import QrPage from '../../pages/QrPage/QrPage'
import ThankUPage from '../../pages/ThankUPage/ThankUPage'
import ShippingConfigPage from '../../pages/ShippingConfigPage/ShippingConfigPage'

import UsersRolesPage from '../../pages/UsersRolesPage/UsersRolesPage'
import UserStoresTablePage from '../../pages/UserStoresTablePage/UserStoresTablePage'
import WhatsAppWidget from '../../components/WhatsAppWidget/WhatsAppWidget'
import WizardSelectPlan from '../../components/BillingPage/WizardSelectPlan/WizardSelectPlan'
import ClientInfoPage from '../../pages/ClientInfoPage/ClientInfoPage'
import NotificationPopup from '../../components/Popups/notifications'

//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel'

// Internal dependencies
import { getStoreService } from '../../services/store'
import { getStoreUsageService } from '../../services/billing'
import { getNotificationsStatic } from '../../services/notifications'
import { logOut } from '../../modules/auth'
import { setStoreData } from '../../modules/common'
import { routes } from '../../constants.js/misc'
import { useWelcomeMessage } from '../../hook/use-welcome-message'

// Styles
import {
    drawerStyles,
    UserMenu,
    TopBar,
    FooterBar,
    ContentItems,
    Actions,
    IconOvalo,
    InfoNotification,
    NotificationContainer,
    ContainerFreePlanAlert
} from './AppContainer.styles';
import DownloadReportPage from '../../pages/DownloadReportPage/DownloadReportPage.js'
import PopupFinishTrialPlan from '../../components/BillingPage/PopupFinishTrialPlan/PopupFinishTrialPlan.js'

const AppContainer = ({ storeId, isLoggingOut, logOut, userEmail, userType, setStoreData, storeData, showWelcomePopup }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const classes = drawerStyles()

    // State variables
    const [anchorEl, setAnchorEl] = useState(null)
    const [collectionIsActive, setCollectionIsActive] = useState(false)
    const [storeDefault, setStoreDefault] = useState({})
    const [notificationMessageStatic, setnotificationMessageStatic] = useState('')
    const [storeNameDefault, setNameStoreDefault] = useState('')
    const [open, setOpen] = useState(true)
    const [openNotification, setOpenNotification] = useState(false)
    const [ordersIsActive, setOrdersIsActive] = useState(false)
    const [inventoryIsActive, setInventoryIsActive] = useState(false)
    const [clientIsActive, setClientIsActive] = useState(false)
    const [configIsActive, setConfigIsActive] = useState(false)
    const [homeIsActive, setHomeIsActive] = useState(false)
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
    const [productsIsActive, setProductsIsActive] = useState(false)
    const [title, setTitle] = useState(false)
    const [infoStore, setInfoStore] = useState('')
    const [selectPlanDialogOpen, setSelectPlanDialogOpen] = useState(false)
    const [showPlanDialogOpen, setShowPlanDialogOpen] = useState(false)
    const [wsIsActive, setWsIsActive] = useState(false);
    const storedDate = storeData?.store_plan?.trial_ended_at;
    const storedDateObj = new Date(storedDate);
    const currentDateObj = new Date();
    const showBannerFreeTrial = currentDateObj <= storedDateObj;
    const { showPopup, setShowPopup } = useWelcomeMessage();
    const [openWelcomePopup, setOpenWelcomePopup] = useState(showPopup);

    const routeTitles = {
        [routes.addPaymentProviders]: t('Config.paymentProviders'),
        [routes.collectionsPage]: t('CollectionsTitle'),
        [routes.config]: t('ConfigTitle'),
        [routes.createCollection]: t('CollectionsTitle'),
        [routes.createProduct]: t('ProductsTitle'),
        [routes.createUserRole]: t('UserRole.route'),
        [routes.createUserStore]: t('UserStoresTitle'),
        [routes.createOrder]: t('OrderTitle'),
        [routes.domain]: t('Config.domain'),
        [routes.form]: t('Config.form'),
        [routes.general]: t('Config.generalRoute'),
        [routes.homeUrl]: t('HomePage'),
        [routes.importProducts]: t('ImportProducts.titleRoute'),
        [routes.legal]: t('Config.legalRoute'),
        [routes.ordersPage]: t('OrdersTitle'),
        [routes.inventoryPage]: t('InventoryTitle'),
        [routes.inventoryImportPage]: t('InventoryImportTitle'),
        [routes.clientPage]: t('ClientTitle'),
        [routes.paymentProviders]: t('Config.paymentProviders'),
        [routes.productsTable]: t('ProductsTitle'),
        [routes.qrCode]: t('Config.qrCode'),
        [routes.shipping]: t('Config.shippingRoute'),
        [routes.store]: t('wsTitleStore'),
        [routes.updateCollection]: t('CollectionsTitle'),
        [routes.updateProduct]: t('ProductsTitle'),
        [routes.updateUserRole]: t('UserRole.route'),
        [routes.updateUserStore]: t('UserStoresTitle'),
        [routes.upgradePaymentProviders]: t('Config.paymentProviders'),
        [routes.usersRoles]: t('UserRole.route'),
        [routes.userStores]: t('UserStoresTitle'),
    }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    // Functions
    
    const toggleDrawer = () => {
        setOpen(!open);
        setMobileDrawerOpen(!mobileDrawerOpen);
    };

    const handleUserMenuClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const logoutAndGoHome = () => {
        logOut();
        history.push('/');
        // unloadHubspotChat();
    }

    const currentPathName = history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/')) || history.location.pathname;

    const setTitles = () => {
        const currentPathName = history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/')) || history.location.pathname;

        setTitle(routeTitles[history.location.pathname] || routeTitles[currentPathName]);

        setProductsIsActive(currentPathName === routes.productsTable ||
            currentPathName === routes.createProduct ||
            currentPathName === routes.editProduct)
        setCollectionIsActive(currentPathName === routes.collectionsPage ||
            currentPathName === routes.updateCollection)
        setOrdersIsActive(currentPathName === routes.ordersPage)
        setInventoryIsActive(currentPathName === routes.inventoryPage)
        setClientIsActive(currentPathName === routes.clientPage)
        setHomeIsActive(currentPathName === routes.homeUrl)
        setConfigIsActive(currentPathName === routes.config)
        setWsIsActive(currentPathName === routes.store ||
            currentPathName === routes.userStores ||
            currentPathName === routes.createUserStore ||
            currentPathName === routes.updateUserStore)
    }

    /**FUNCION PARA CONFIRMAR EL ELMINAR */
    const viewLinkPreview = () => {
        setCancelDialogOpen(true);
    }
    
    const notionHelp = () => window.open('https://www.notion.so/get10x/Centro-de-ayuda-de-10X-8d198608195a4e5faaf23674348c5b12')

    const goPageMenuBar = (value) => {
        switch (value) {
            case 'config':
                history.push(routes.config);
                break;
            default:
                break;
        }
    }

    /**FUNCION PARA MOSTRAR NOTIFICATIONS */
    const viewNotication = () => {
        setOpenNotification(openNotification ? false : true);
    }

    const handleResize = () => {
        if (window.innerWidth < 1024) setOpen(false)
        if (window.innerWidth >= 1024) setOpen(true)
    }

    // const loadHubspotChat = () => {
    //     let link;
    //     link = document.createElement("script");
    //     link.type = "text/javascript";
    //     link.src = "https://js-na1.hs-scripts.com/23241249.jss";
    //     link.id = "hs-script-loader";
    //     document.body.appendChild(link);
    // }

    // const unloadHubspotChat = () => {
    //     if (document.getElementById('hs-script-loader')){
    //         document.getElementById('hs-script-loader').remove();
    //     }

    //     if (document.getElementById('hubspot-messages-iframe-container')) {
    //         document.getElementById('hubspot-messages-iframe-container').remove();
    //     }

    //     if (!document.getElementById('hubspot-messages-iframe-container')) {
    //         window.location.reload();
    //     }
    // }
    
    // EFFECTS ⬇️
    useEffect(() => {
        //
        if (!storeNameDefault) {
            getStoreService(storeId)
                .then(({ data }) => {
                    Mixpanel.identify(storeId);
                    setStoreDefault(data);
                    setNameStoreDefault(data.name);
                    Mixpanel.track('Iniciar sesion', {
                        "ViewEnter": 1
                    });
                    // loadHubspotChat();
                    // Save store data in redux
                    setStoreData(data);
                })
                .catch(err =>
                    console.log(err)
                );
        }
  }, [storeNameDefault, storeId]);

  history.listen(() => setTitles());

  useEffect(() => {
    const viewPopup = localStorage.getItem("showPopupLinks");
    if (!viewPopup) {
      setOpenNotification(true);
    }

    setTitles();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!notificationMessageStatic) {
      getNotificationsStatic(storeId)
        .then(({ data }) => {
          if (data.length > 0) {
            setOpenNotification(true);
          }
          setnotificationMessageStatic(data[0]);
        })
        .catch((err) => {});
    }
  }, [notificationMessageStatic, storeId]);

  useEffect(() => {
    if (!infoStore) {
      getStoreUsageService(storeId, "CO")
        .then(({ data }) => {
          setInfoStore(data);
        })
        .catch((err) => {});
    }
  }, [storeData, infoStore]);

  useEffect(() => {
    if (storeData) {
      if (storeData.store_plan) {
        if (
          storeData.store_plan.store_plan_status_id !== 3 ||
          (storeData.store_plan.store_plan_status_id === 3 &&
            storeData.store_plan.next_cut_date === null)
        ) {
          // habilitar para mostrar pop select plan
          // setSelectPlanDialogOpen(true);
          setShowPlanDialogOpen(true);
        }
      }
    }
  }, [storeData]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={1}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open && !isTabletOrMobile,
        })}
        style={{
          paddingLeft:
            isTabletOrMobile || (open && !isTabletOrMobile) ? 0 : "64px",
        }}
      >
        <TopBar color="transparent" style={{ justifyContent: "space-between", gap: ".5rem" }}>
          <div className={clsx(classes.headerActions)}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              style={{ marginRight: isTabletOrMobile ? 0 : 16 }}
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
            <Typography color='secondary' variant={isTabletOrMobile ? "body" : "h6"} noWrap>
              {isTabletOrMobile ? storeData.name : title}
            </Typography>
            {isTabletOrMobile && (
              <img style={{ height: "32px" }} src={logo} alt="logo" />
            )}
          </div>
          {!isTabletOrMobile && (
            <Actions>
              <Button
                className="btnLink"
                onClick={viewLinkPreview}
                color="primary"
                variant="contained"
              >
                {t("PreviewLink")}
              </Button>

              <div style={{ display: "flex" }}>
                <NotificationContainer onClick={() => viewNotication()}>
                  <BellNotification />
                </NotificationContainer>
                <Avatar className={classes.avatarUSer}>
                  {userEmail.slice(0, 2)}
                </Avatar>
                <IconOvalo src={OvaloIcon}></IconOvalo>
              </div>

              <UserMenu
                anchorEl={anchorEl}
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleUserMenuClick}
              >
                <div className="email">
                  <Typography variant="body2">{userEmail}</Typography>
                  {userType === 1 && (
                    <Typography variant="caption">
                      <b>Administrador</b>
                    </Typography>
                  )}
                </div>
                <KeyboardArrowDownIcon />
              </UserMenu>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                style={{ top: 55 }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => goPageMenuBar("config")}>
                  {t("MenuHeader.config")}
                </MenuItem>
                <MenuItem onClick={notionHelp}>{t("MenuHeader.help")}</MenuItem>
                <MenuItem onClick={logoutAndGoHome}>{t("Logout")}</MenuItem>
              </Menu>
            </Actions>
          )}
        </TopBar>
        {showBannerFreeTrial && (
          <ContainerFreePlanAlert>
            {/* <AlertPaymentPlan /> */}
          </ContainerFreePlanAlert>
        )}
      </AppBar>
      {isTabletOrMobile ? (
        <MobileDrawer
          open={mobileDrawerOpen}
          onClose={() => setMobileDrawerOpen(false)}
          logOut={logoutAndGoHome}
          dataStore={storeDefault}
        />
      ) : (
        <Drawer
          variant="permanent"
          anchor="left"
          open={open}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.drawerHeader}>
            <img
              style={{
                height: open && !isTabletOrMobile ? "46px" : "27px",
                transition: "0.5s",
              }}
              src={logo}
              alt="logo"
            ></img>
            <Typography variant="body1">{storeData?.name}</Typography>
          </div>

          <ContentItems style={{ overflowX: "hidden", overflowY: "hidden" }}>
            <ListItem
              button
              className={classes.listItem}
              selected={homeIsActive}
              style={{ textDecoration: "none", marginTop: "10px" }}
              component={RouterLink}
              to={routes.homeUrl}
            >
              <Tooltip
                title={open && !isTabletOrMobile ? "" : t("HomeTitle")}
                placement="right"
              >
                <ListItemIcon className={classes.iconMenu}>
                  <img src={HomeIcon} alt="Home Icon" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                className={classes.listItemText}
                primary={t("HomeTitle")}
              />
            </ListItem>
          </ContentItems>

          <ContentItems
            style={{ overflowX: "hidden" }}
            className={
              storeDefault && !storeDefault.active ? classes.inactiveForPay : ""
            }
          >
            <List
              subheader={
                <ListSubheader
                  style={{
                    opacity: open && !isTabletOrMobile ? 1 : 0,
                  }}
                  component="div"
                  classes={{ root: classes.titleSubheader }}
                >
                  {t("Menu.myApps")}
                </ListSubheader>
              }
            >
              <ListItem
                selected={currentPathName === routes.store}
                button
                component={RouterLink}
                to={routes.store}
              >
                <Tooltip
                  title={open && !isTabletOrMobile ? "" : t("Menu.config")}
                  placement="right"
                >
                  <ListItemIcon className={classes.iconMenu}>
                    <img src={EditIcon} alt="EditIcon" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  className={classes.listItemText}
                  primary={t("Menu.config")}
                />
              </ListItem>
              <ListItem
                selected={currentPathName === routes.userStores}
                button
                component={RouterLink}
                to={routes.userStores}
              >
                <Tooltip
                  title={open && !isTabletOrMobile ? "" : t("Menu.userStores")}
                  placement="right"
                >
                  <ListItemIcon className={classes.iconMenu}>
                    <img src={WPIcon} alt="WPIcon" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  className={classes.listItemText}
                  primary={t("Menu.userStores")}
                />
              </ListItem>
            </List>

            <List
              subheader={
                <ListSubheader
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  component="div"
                  classes={{ root: classes.titleSubheader }}
                >
                  Administrar
                </ListSubheader>
              }
            >
              <ListItem
                button
                activeClassName={classes.activeItem}
                className={classes.listItem}
                selected={collectionIsActive}
                style={{ textDecoration: "none" }}
                component={RouterLink}
                to={routes.collectionsPage}
              >
                <Tooltip
                  title={open && !isTabletOrMobile ? "" : t("CollectionsTitle")}
                  placement="right"
                >
                  <ListItemIcon className={classes.iconMenu}>
                    <img src={CollectionIcon} alt="CollectionIcon" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  className={classes.listItemText}
                  primary={t("CollectionsTitle")}
                />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                selected={productsIsActive}
                style={{ textDecoration: "none" }}
                component={RouterLink}
                to={routes.productsTable}
              >
                <Tooltip
                  title={open && !isTabletOrMobile ? "" : t("ProductsTitle")}
                  placement="right"
                >
                  <ListItemIcon className={classes.iconMenu}>
                    <img src={ProductIcon} alt="ProductIcon" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  className={classes.listItemText}
                  primary={t("ProductsTitle")}
                />
              </ListItem>
            </List>

            <List
              style={{ paddingBottom: 50 }}
              subheader={
                <ListSubheader
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  component="div"
                  classes={{ root: classes.titleSubheader }}
                >
                  Desempeño
                </ListSubheader>
              }
            >
              <ListItem
                button
                activeClassName={classes.activeItem}
                className={classes.listItem}
                selected={ordersIsActive}
                style={{ textDecoration: "none" }}
                component={RouterLink}
                to={routes.ordersPage}
              >
                <Tooltip
                  title={open && !isTabletOrMobile ? "" : t("OrdersTitle")}
                  placement="right"
                >
                  <ListItemIcon className={classes.iconMenu}>
                    <img src={OrdersIcon} alt="OrdersIcon" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  className={classes.listItemText}
                  primary={t("OrdersTitle")}
                />
              </ListItem>

              <ListItem
                button
                activeClassName={classes.activeItem}
                className={classes.listItem}
                selected={inventoryIsActive}
                style={{ textDecoration: "none" }}
                component={RouterLink}
                to={routes.inventoryPage}
              >
                <Tooltip
                  title={open && !isTabletOrMobile ? "" : t("InventoryTitle")}
                  placement="right"
                >
                  <ListItemIcon className={classes.iconMenu}>
                    <img src={InventoryIcon} alt="InventoryTitle" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  className={classes.listItemText}
                  primary={t("InventoryTitle")}
                />
              </ListItem>

              <ListItem
                button
                className={classes.listItem}
                selected={clientIsActive}
                style={{ textDecoration: "none" }}
                component={RouterLink}
                to={routes.clientPage}
              >
                <Tooltip
                  title={open && !isTabletOrMobile ? "" : t("ClientTitle")}
                  placement="right"
                >
                  <ListItemIcon className={classes.iconMenu}>
                    <img src={ClientsIcon} alt="ClientsIcon" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                  className={classes.listItemText}
                  primary={t("ClientTitle")}
                />
              </ListItem>
            </List>
          </ContentItems>

          <FooterBar>
            <Divider />
            <ListItem
              button
              activeClassName={classes.activeItem}
              selected={configIsActive}
              component={RouterLink}
              to={routes.config}
              className={classes.listItem}
              style={{ textDecoration: "none" }}
            >
              <Tooltip
                title={open && !isTabletOrMobile ? "" : t("ConfigTitle")}
                placement="right"
              >
                <ListItemIcon className={classes.iconMenu}>
                  <img src={ConfigIcon} alt="ConfigIcon" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                style={{ opacity: open && !isTabletOrMobile ? 1 : 0 }}
                className={classes.listItemText}
                primary={t("ConfigTitle")}
              />
            </ListItem>
          </FooterBar>
        </Drawer>
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open && !isTabletOrMobile,
        })}
      >
        {notificationMessageStatic && (
          <InfoNotification isTabletOrMobile={isTabletOrMobile}>
            <Typography variant="p">
              {notificationMessageStatic.notification}{" "}
              <Link component={RouterLink} to={notificationMessageStatic.url}>
                {notificationMessageStatic.url_label}
              </Link>
            </Typography>
          </InfoNotification>
        )}
        {!notificationMessageStatic && <div className={classes.drawerHeader} />}
        <section className={`${classes.container} `}>
          <Route
            exact
            path={routes.homeUrl}
            component={isTabletOrMobile ? HomePageMobile : HomePage}
          />
          <Route exact path={routes.collectionsPage} component={Collections} />
          <Route
            exact
            path={routes.createCollection}
            component={ManageCollection}
          />
          <Route exact path={routes.createOrder} component={CreateOrder} />
          <Route
            exact
            path={routes.createProduct}
            component={ManageProductPage}
          />
          <Route
            exact
            path={routes.createUserStore}
            component={ManageUserStorePage}
          />
          <Route exact path={routes.ordersPage} component={OrdersTablePage} />
          <Route exact path={routes.clientPage} component={ClientsPage} />
          <Route
            exact
            path={routes.clientPage + "/:id"}
            component={ClientInfoPage}
          />
          <Route
            exact
            path={routes.ordersPage + "/:id"}
            component={OrderInfoPage}
          />
          <Route
            exact
            path={routes.productsTable}
            component={ProductsTablePage}
          />
          <Route exact path={routes.store} component={ManageStorePage} />
          <Route exact path={routes.domain} component={DomainPage} />
          <Route exact path={routes.form} component={FormPage} />
          <Route
            exact
            path={routes.paymentProviders}
            component={PaymentProvidersPage}
          />
          <Route
            exact
            path={routes.addPaymentProviders}
            component={AddPaymentProvidersPage}
          />
          <Route
            exact
            path={routes.upgradePaymentProviders}
            component={AddPaymentProvidersPage}
          />
          <Route exact path={routes.qrCode} component={QrPage} />
          <Route exact path={routes.thankU} component={ThankUPage} />
          <Route
            exact
            path={routes.updateCollection + "/:id"}
            component={ManageCollection}
          />
          <Route
            exact
            path={routes.updateProduct + "/:id"}
            component={ManageProductPage}
          />
          <Route
            exact
            path={routes.updateUserStore + "/:id"}
            component={ManageUserStorePage}
          />
          <Route
            exact
            path={routes.userStores}
            component={UserStoresTablePage}
          />
          <Route exact path={routes.config} component={ConfigPage} />
          <Route exact path={routes.billing} component={BillingPage} />
          <Route exact path={routes.bill + "/:id"} component={BillDetailPage} />
          <Route
            exact
            path={routes.payments + "/:planId/:paymentTypeId"}
            component={PaymentPage}
          />
          <Route
            exact
            path={routes.payBill + "/:id"}
            component={BillPaymentPage}
          />
          <Route exact path={routes.inventoryPage} component={InventoryPage} />
          <Route
            exact
            path={routes.inventoryImportPage}
            component={InventoryImportPage}
          />
          <Route
            exact
            path={routes.importProducts}
            component={ImportProductsPage}
          />
          <Route
            exact
            path={routes.massiveUploadTable}
            component={MassiveUploadTable}
          />
          <Route exact path={routes.general} component={GeneralPage} />
          <Route exact path={routes.legal} component={LegalPage} />
          <Route exact path={routes.shipping} component={ShippingConfigPage} />
          <Route exact path={routes.usersRoles} component={UsersRolesPage} />
          <Route
            exact
            path={routes.createUserRole}
            component={ManageUserRole}
          />
          <Route
            exact
            path={routes.updateUserRole + "/:id"}
            component={ManageUserRole}
          />
          <Route
            exact
            path={routes.mobileManageCategories}
            component={ActionCategorieMobile}
          />
          <Route
            exact
            path={routes.downloadReportPage}
            component={DownloadReportPage}
          />
          {isTabletOrMobile && <FooterMenuBar />}
        </section>
      </main>
      {storeNameDefault && (
        <PreviewLinkStore
          open={cancelDialogOpen}
          dataStore={storeDefault}
          storeId={storeId}
          onClose={() => setCancelDialogOpen(false)}
          title={t("PreviewLink.title")}
          message={t("PreviewLink.subtitle")}
        />
      )}
      {
        openWelcomePopup && (
          <NotificationPopup
            openNotification={openWelcomePopup}
            onClose={() => {
              setOpenWelcomePopup(false)
              setShowPopup(false)
            }}
          /> 
        )
      }
      {/* No borrar para su futura implementacion */}
      {/* {
        openNotification && (
          // <PopupNotification      ***************************** notification menu ***********************************************
          //     open={cancelDialogOpen}
          //     dataNotifications={notificationMessageStatic ? notificationMessageStatic.notification : ''}
          //     onClose={() => setOpenNotification(false)}
          //     title={t('PreviewLink.title')}
          // />

          <NotificationPopup
            openNotification={openNotification}
            onClose={() => setOpenNotification(false)}
          />
        )
        // <QuestionSelection
        //    openNotification={openNotification}
        //    onClose={() =>  setOpenNotification(false)}
        // />
        // <OpenEndedQuestions
        //     openNotification={openNotification}
        //     onClose={() =>  setOpenNotification(false)}
        //     storeId={storeId}
        // />
      } */}
      <WhatsAppWidget
        phoneNumber="573003154556"
        textReplyTime={false}
        message="¡Hola! 👋🏼  ¿En qué te puedo ayudar?"
        companyName="Soporte ecometri"
        placeholder="Escribe un mensaje"
        sendButton="Enviar"
      />
      <WizardSelectPlan
        open={selectPlanDialogOpen}
        currentPlan={infoStore}
        storeId={storeId}
        onClose={() => setSelectPlanDialogOpen(false)}
        logOut={logOut}
        disableCloseBtn
        disableBackdropClick
        disableEscapeKeyDown
      />
      {/* TODO: don't delete, disable popup*/}
      {/* <PopupPayAnnualDiscount open={showWelcomePopup} /> */}
      <PopupFinishTrialPlan open={showPlanDialogOpen} disableEscapeKeyDown />
    </div>
  );
};

const mapStateToProps = ({ auth, common }) => ({
    storeId: auth.storeId,
    isLoggingOut: auth.isUserAuthenticated,
    userEmail: auth.userEmail,
    userType: auth.userType,
    storeData: common.storeData,
    showWelcomePopup: common.showWelcomePopup
});

const mapDispatchToProps = dispatch => bindActionCreators({
    logOut,
    setStoreData,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppContainer);
