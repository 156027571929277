import { axiosInstance, serverInstance, twilioVerifyInstance, hubSpotInstance } from './instance';
import store from '../../src/store';

/**
 * Authenticates the user
 * @param {string} user email
 * @param {string} pass password
 */
const loginService = (user, pass) => {
    return axiosInstance.post('/users/auth', {
        user_name: user,
        password: pass
    });
};

/**
 * Creates a new user
 * @param {Object} signUpData sign up forms data
 */
const signUpService = (signUpData) => {
    return axiosInstance.post('/users', signUpData);
}


const generateCodeAuthWeb = () => {
    return axiosInstance.get(`/users/token`);
};


const generateRecoveryPasswordService = (value) => {
    return axiosInstance.post(`/users/reset?email=${value.email}`, {});
};

const changePasswordService = (value) => {
    return axiosInstance.put(`/users/change-password`, value);
};



const loginCodeService = (value) => {
    return axiosInstance.get(`/users/validate/token/${value}`);
};


/**
 * Verify number via WhatsApp
 */
const verifyNumberService = (data) => {
    return twilioVerifyInstance.post(
        `/Services/${process.env.REACT_APP_TWILIO_VERIFY_SERVICE_SID}/Verifications`,
        new URLSearchParams(data),
        {
            auth: {
                username: process.env.REACT_APP_TWILIO_ACCOUNT_SID,
                password: process.env.REACT_APP_TWILIO_AUTH_TOKEN
            }
        }
    );
};

const checkNumberService = (data) => {
    return twilioVerifyInstance.post(
        `/Services/${process.env.REACT_APP_TWILIO_VERIFY_SERVICE_SID}/VerificationCheck`,
        new URLSearchParams(data),
        {
            auth: {
                username: process.env.REACT_APP_TWILIO_ACCOUNT_SID,
                password: process.env.REACT_APP_TWILIO_AUTH_TOKEN
            }
        }
    );
};

/**
 * Register SignUp data on hubspot
 */
// const createHubSpotContact = (value) => {
//     return hubSpotInstance.post(`/objects/contacts`, value);
// }

/**
 * Returns the user status
 */
const statusService = () => {
    return serverInstance.get('/status', {
        params: {
            email: store.getState().auth.userEmail || ''
        }
    });
}

/**
 * Returns the user status
 */
const logoutService = () => {
    return serverInstance.get('/logout', {
        params: {
            email: store.getState().auth.userEmail || ''
        }
    });
}

export {
    loginService,
    signUpService,
    statusService,
    logoutService,
    generateCodeAuthWeb,
    loginCodeService,
    changePasswordService,
    generateRecoveryPasswordService,
    verifyNumberService,
    checkNumberService,
}